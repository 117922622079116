import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  RobotoMedium,
  RobotoNormal,
  RobotoNormal2,
} from "../styles/PageStyles.js"

const ProjectsContent = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "projects-page" } }) {
        frontmatter {
          projects {
            title
            typeOfProject
            technologyStack
            description
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark
  const { projects } = frontmatter
  return (
    <StyledContainer fluid>
      {projects.map((project, index) => (
        <div key={index}>
          {index % 2 === 0 ? (
            <StyledRow>
              <StyledCol
                lg={6}
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="200"
                data-aos-offset="400"
              >
                <GatsbyImage
                  image={getImage(project.image)}
                  alt={project.title}
                />
              </StyledCol>
              <StyledCol
                lg={6}
                data-aos="fade-left"
                data-aos-delay="700"
                data-aos-offset="300"
              >
                <RobotoMedium>{project.title}</RobotoMedium>
                <RobotoNormal>{project.typeOfProject}</RobotoNormal>
                <RobotoNormal2 style={{ marginTop: 15 }}>
                  {project.description}
                  <p style={{ marginTop: 15 }}>
                    Technology Stack: {project.technologyStack}
                  </p>
                </RobotoNormal2>
              </StyledCol>
            </StyledRow>
          ) : (
            <StyledRow>
              <StyledCol
                lg={6}
                data-aos="fade-right"
                alignRight
                data-aos-delay="700"
                data-aos-offset="300"
              >
                <RobotoMedium>{project.title}</RobotoMedium>
                <RobotoNormal>{project.typeOfProject}</RobotoNormal>
                <RobotoNormal2 style={{ marginTop: 15 }}>
                  {project.description}
                  <p style={{ marginTop: 15 }}>
                    Technology Stack: {project.technologyStack}
                  </p>
                </RobotoNormal2>
              </StyledCol>
              <StyledCol
                lg={6}
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-delay="200"
                data-aos-offset="400"
              >
                <GatsbyImage
                  image={getImage(project.image)}
                  alt={project.title}
                />
              </StyledCol>
            </StyledRow>
          )}
        </div>
      ))}
    </StyledContainer>
  )
}

export default ProjectsContent

const StyledContainer = styled(Container)`
  padding: 5vh 0 0 0;
`

const StyledRow = styled(Row)`
  display: flex;
  padding-bottom: 10vh;
  align-items: center;
  padding: 0 10% 0 10%;

  @media screen and (max-width: 768px) {
    flex-direction: ${({ reversed }) =>
      reversed ? "column-reverse" : "column"};
  }
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: ${({ alignRight }) => (alignRight ? "right" : "left")};
  padding-top: 10vh;
  padding-bottom: 10vh;

  @media screen and (max-width: 768px) {
    text-align: center;
    padding: 2vh 0 2vh 0;
  }

  img {
    width: 100%;

    &:hover {
      //
    }
  }
`
