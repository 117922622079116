import React from "react"
import { SEO as Seo } from '../../components/SEO.js'
import Layout from "../../components/Layout"
import ProjectsContent from "../../components/ProjectsContent"
import ProjectsHeader from "../../components/ProjectsHeader"

export default function Projects() {
  return (
    <Layout>
      <Seo 
        title="Blackfort - Projects"
        description="We take great pride with our successful projects with these top tier companies: from finance, to logistics, local cooperative, to air travel support. With talented teams full of software developing experts, we put customers' satisfaction with the 1st priority."
        url="https://www.blackfort.ph/projects"
      />
      <ProjectsHeader />
      <ProjectsContent />
    </Layout>
  )
}
